export const environment = {
  production: false,
  apiUrl: 'https://homolog.api.pagamento.itcnet.com.br',
  keycloak: {
    authority: 'https://homolog.keycloack.itcnet.com.br',
    redirectUri: 'https://homolog.pagamento.itcnet.com.br/',
    postLogoutRedirectUri: 'https://homolog.pagamento.itcnet.com.br/planos',
    realm: 'itc_homolog',
    clientId: 'clientid',
  },
  idleConfig: { idle: 10, timeout: 60, ping: 10 },
  efiAccount: 'bac6e21470e39303498ee4589f900354',
  efiEnvironment: 'sandbox' as 'production' | 'sandbox'
};